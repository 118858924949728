/* global
 autosize
 bsCustomFileInput
 checkRedirect
 gettext
 initHtmlTextarea
 removeText
 $x
 */


// #############################################################################
// GLOBAL VARS

const $body = $("body");

// #############################################################################
// BETTER FOCUS

const $betterFocus = new $x.BetterFocus();
$betterFocus.init();

// #############################################################################
// TOOLTIP

$("[data-toggle=tooltip]").tooltip();

// #############################################################################
// FORM: MULTIPLE SELECT ITEMS

$x.initMultipleSelectItems = () => {
  const $inputs = document.querySelectorAll("[data-multiple-select-item]");

  $inputs.forEach(function ($input) {
    if ($input.$multipleSelectItem) {
      return;
    }

    $input.$multipleSelectItem = new $x.MultipleSelectItems($input);
    $input.$multipleSelectItem.init();
  });
};

// #############################################################################
// FORM

$x.initFormDefaults = function ($parent = $body) {
  // File
  // TODO: Selber schreiben!
  bsCustomFileInput.init();

  // Autosize
  autosize($("textarea", $parent));

  // HTML CKEditor
  initHtmlTextarea($parent);

  // Range
  $("[type=range]", $parent).formRange();

  // Ajax upload
  const $ajaxUpload = new $x.AjaxUpload($("[data-ajax-upload]", $parent), {
    onUploadCompleted: function ($upload, $data) {
      $x.replaceHtml($data);
    },
  });

  $ajaxUpload.init();

  // File tree
  $("[data-file-tree]", $parent).formFileTree();

  // Form set
  $("[data-form-set]", $parent).formSet();

  $x.initMultipleSelectItems();

  return {
    ajaxUpload: $ajaxUpload,
  };
};

const $formDefaults = $x.initFormDefaults();

// Validation

$("[data-form]").formValidation({
  beforeSubmit: function () {
    $formDefaults.ajaxUpload.reset();
  },
  afterSubmit: function ($xhr, $form, $data) {
    if ($data.submit === "success") {
      if ($data.redirect) {
        checkRedirect($data);
      } else {
        $x.replaceHtml($data);

        if ($data.toaster) {
          $body.toaster("updateToaster", $data.toaster);
        }
      }
    }
  },
});

// Wizard

$("[data-form-wizard]").formWizard();


// #############################################################################
// DATA TABLE

const $dataTables = document.querySelectorAll("[data-table]");

$dataTables.forEach(function ($item) {
  $item.$datatable = new $x.DataTables($item, {
    api: function ($table, $api) {
      // API: https://datatables.net/reference/api/

      const $input = $table.querySelector("[data-multiple-select-item]");

      $api.on("draw", function () {
        $("[data-toggle=tooltip]", $table).tooltip();

        if ($input) {
          $input.$multipleSelectItem.reset();
        }
      });
    },
    customizeCSV: function (csv) {
      // For customization read https://datatables.net/reference/button/csv

      return csv;
    },
    rowGroupStartRender: function ($table, $rows, html) {
      return html;
    },
  });
});

// #############################################################################
// FULL CALENDAR

const $calendars = document.querySelectorAll("[data-calendar]");

$calendars.forEach(function ($item) {
  $item.$calendar = new $x.FullCalendar($item);
});

// #############################################################################
// CHART JS

const $charts = document.querySelectorAll("[data-chartjs]");

$charts.forEach(function ($element) {
  const $chartJS = new $x.ChartJS($element);
  $chartJS.init();
});

// #############################################################################
// MODAL

$x.onModalOpenDefault = function ($modal) {
  $("[autofocus]", $modal).focus();
  $("[data-toggle=tooltip]", $modal).tooltip();

  if ($(".django-select2").length) {
    // Init Select2 Fields in the modal dialog, only if there is any django-select2 field.
    $(".django-select2").djangoSelect2({
      dropdownParent: $("#modal"),
    });
  }

  const $formDefaults = $x.initFormDefaults($modal);

  // Validation

  $("[data-form]", $modal).formValidation({
    beforeSubmit: function () {
      $formDefaults.ajaxUpload.reset();
    },
    afterSubmit: function ($xhr, $form, $data) {
      if ($data.submit === "success") {
        $modal.modal("hide");

        if ($data.redirect) {
          checkRedirect($data);
        } else {
          $x.replaceHtml($data);

          if ($data.toaster) {
            $body.toaster("updateToaster", $data.toaster);
          }

          $dataTables.forEach(function ($item) {
            $item.$datatable.reload();
          });

          $calendars.forEach(function ($item) {
            $item.$calendar.$api.refetchEvents();
          });
        }
      }
    },
  });

  // Wizard

  $("[data-form-wizard]", $modal).formWizard();
};

$x.delegateEvent.on(document, "click", "[data-modal-link]", function (e) {
  e.preventDefault();

  $x.modal.open(this.href, {
    onModalOpen: $x.onModalOpenDefault,
  });
});

$x.delegateEvent.on(document, "click", "[data-ajax-link]", function (e) {
  e.preventDefault();

  $x.ajax.get(this.href, {
    success: function ($data) {
      $dataTables.forEach(function ($item) {
        $item.$datatable.reload();
      });
    },
  });
});

// #############################################################################
// DOWNLOAD BLOB

$x.delegateEvent.on(document, "click", "[data-download]", function (e) {
  e.preventDefault();

  const $downloadBlob = new $x.DownloadBlob({
    onDownloadStarted: function ($data) {
      $body.toaster("updateToaster", $data.toaster);

      $dataTables.forEach(function ($item) {
        $item.$datatable.reload();
      });
    },
  });

  $downloadBlob.download(this.href);
});

// #############################################################################
// CLIPBOARD

$body.clipBoard({
  selector: "[data-clipboard]",
});

// #############################################################################
// TOASTER

$body.toaster({
  selector: "[data-toaster]",
});

// #############################################################################
// AUTO UPDATE HTML CONTENT

// TODO: Demo erstellen

$body.autoUpdateHtmlContent({
  selector: "[data-update-html-content]",
});

// ##############################################
// QR TEXT
$body.on("blur", "#id_catererform-qr_text", function () {
  const qr_details = new QRTextDetails($(this).val());
  if (qr_details.isValid) {
    $("#id_catererform-qr_id").val(qr_details.qrId);
  } else {
    $(`#${this.id}_error`).addClass("d-block").html(gettext("Please enter a valid qr text"));
  }
}).on("input", "#id_catererform-qr_text", function () {
  $(`#${this.id}_error`).removeClass("d-block").html();
});

class QRTextDetails {
  constructor (qrText) {
    this.qrText = qrText;
  }

  get qrCodeParts() {
    return this.qrText.split("_");
  }

  get isValid() {
    return this.qrCodeParts.length >= 11;
  }

  get qrId () {
    const codeTypePosition = 1;
    const codePosition = 11;
    const codeTypeAT0 = "AT0";
    const textParts = this.qrCodeParts;

    const type = textParts[codeTypePosition].split("-")[1];

    if (type === codeTypeAT0) {
      return `${type}:${textParts[codePosition].split("-")[0]}`;
    } else {
      return `${type}:${textParts[codePosition]}`;
    }
  }
}
// ##################################################
